import React from 'react';
import _ from 'lodash';
import { Button } from 'antd';
import { action, observable } from 'mobx';
import { request, SearchListModal } from '../../utils';
import type { BaseData, IMainSubStructureModel, MainSubStructureModel, NormalProgrammeParams } from '../../utils';
import { API } from './api';
import dayjs from 'dayjs';
import { withdrawalSourceDict } from '../../utils/dict';

export default class Model {
  constructor() {
    this.pageStore.grid.onQuery();
  }

  @observable
  public total = 0;

  public filterSet: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        type: 'input',
        label: '用户ID',
        field: 'userId',
      },
      {
        type: 'input',
        label: '用户昵称',
        field: 'nickName',
      },
      {
        type: 'input',
        label: '手机号',
        field: 'mobile',
      },
    ],
  };

  @action
  public setCollectData = () => {
    this.pageStore.grid.collectData = [
      {
        name: '总量',
        value: this.total || 0,
        color: 'blue',
      },
    ];
  };

  public grid: IMainSubStructureModel = {
    collectData: [
      {
        name: '总量',
        value: this.total,
        color: 'blue',
      },
    ],
    grid: {
      columns: [
        {
          key: 'id',
          name: '订单编号',
        },
        {
          key: 'batchId',
          name: '第三方订单编号',
        },
        {
          key: 'amount',
          name: '订单金额',
        },
        {
          key: 'nickName',
          name: '用户',
        },
        {
          key: 'createTime',
          name: '提现时间',
          formatter: ({ row }) => (row.createTime ? dayjs(row.createTime).format('YYYY-MM-DD HH:mm:ss') : ''),
        },
        {
          key: 'withdrawSuccessTime',
          name: '到账时间',
          formatter: ({ row }) => (row.withdrawSuccessTime ? dayjs(row.withdrawSuccessTime).format('YYYY-MM-DD HH:mm:ss') : ''),
        },
        {
          key: 'withdrawSource',
          name: '提现渠道',
          formatter: ({ row }) => withdrawalSourceDict.find((item) => item.dictValue === row.withdrawSource)?.dictName,
        },
        {
          key: 'detailStatus',
          name: '提现状态',
          formatter: ({ row }) => {
            if (row.detailStatus === 'SUCCESS') {
              return '成功';
            }
            if (row.detailStatus === 'FAIL') {
              return '失败';
            }
            return '';
          },
        },
      ].map((item) => ({
        resizable: true,
        sortable: false,
        ...item,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showSelectedTotal: false,
    },
    api: {
      onQuery: (params) => {
        const { filterParams, pageNum, pageSize } = params;
        const postParams = {
          ...filterParams,
          pageNum,
          pageSize,
        };

        return request<BaseData<any>>({
          url: API.fetchWithdrawalList,
          method: 'POST',
          data: postParams,
        });
      },
      callbackAfterQuery: (_this: MainSubStructureModel) => {
        this.total = _this.gridModel.total;
        this.setCollectData();
      },
    },
  };

  public pageStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });
}
